import React from 'react';
import {getLink, getRoutes} from "../../components/shared/routes";
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";

export default () => (<RedirectTo url={getLink([getRoutes().UseCases])} />);

// import React from 'react';
// import Layout from '../../components/shared/Layout';
// import SEO from '../../components/shared/SEO';
// import {useTranslation} from 'react-i18next';
// import css from './sharing.module.scss';
// import Footer from "../../components/shared/Footer/Footer";
// import {graphql, useStaticQuery} from 'gatsby';
// import {getImagesFluid} from '../../components/componentsUtils';
// import CoveredVideo from "../../components/shared/CoveredVideo/CoveredVideo";
// import SlideTypeF from "../../components/shared/ImageSlider/SlideTypeF";
// import SlideTypeG from "../../components/shared/ImageSlider/SlideTypeG";
// import Movie from "../../movies/Inspirations/Sharing/inspirations-sharing.mp4";
// import SharingIconBlack from "../../images/icons/sharing_b.svg";
// import GoToButton from "../../components/shared/GoToButton/GoToButton";
// import {getId, getLink, getRoutes} from "../../components/shared/routes";

// const query = graphql`
// {
//   fluid: allFile(filter: {sourceInstanceName: {eq: "images_Sharing"}}) {
//     nodes {
//       childImageSharp {
//         fluid(maxWidth: 1090, quality: 70) {
//           ...GatsbyImageSharpFluid,
//           originalName
//         }
//       }
//     }
//   }
// }`;

// const SharingPage = () => {
//   const { t } = useTranslation();
//   const imagesFluid = getImagesFluid(useStaticQuery(query));
//   const pageTitle = `${getRoutes().Sharing.pageTitle} | ${getRoutes().Inspirations.pageTitle}`;

//   return (
//     <Layout>
//       <SEO title={pageTitle} description={'Efficient Image posting'}/>

//       <div className={css.wrapper}>

//         <div className={css.private}>
          
//             <div className={css.wrapper}>
//             <div className={css.slide1}>
//             <CoveredVideo
//                 videoSrcURL={Movie}
//                 placeholderImage={'sharing_1_placeholder.png'}
//                 imagesFluid={imagesFluid}
//                 icon={SharingIconBlack}
//                 title="Sharing"
//                 text={<>Caring about appreciation is <br/>posting professional look photos.</>}
//                 buttons={(
//                     <GoToButton
//                         sectionUrl={getLink([getRoutes().Sharing, getRoutes().Sharing.sections.getStarted])}
//                         text="Get started!"
//                     />
//                 )}
//             />
//             </div>
//             <div className={css.slide2} id={getRoutes().Sharing.sections.getStarted}>
//             <SlideTypeF
//                 title={<>Process <br/>workflow automation</>}
//                 text1={<p>Sharp and detailed photo.</p>}
//                 text2={<p>Processed in real time.</p>}
//                 fullImage={'sharing_2.jpg'}
//                 fullImageAlt="optimisation artifacts removing upscaling"
//                 phoneImage1={'sharing_mobile_1.jpg'}
//                 phoneImage1Alt="optimisation"
//                 phoneImage2={'sharing_mobile_2.jpg'}
//                 phoneImage2Alt="artifacts removing"
//                 phoneImage3={'sharing_mobile_3.jpg'}
//                 phoneImage3Alt="upscaling"
//                 imagesFluid={imagesFluid}
//                 imagePosition={'right'}
//             />
//             </div>
//             <div className={css.slide3}>
//             <SlideTypeF
//                 title="Pre-defined workflow "
//                 text1={<p>Simple in use.</p>}
//                 text2={<p>Share your appealing photos.</p>}
//                 fullImage={'sharing_3.jpg'}
//                 fullImageAlt={"anonymisation auto cropping metadata"}
//                 phoneImage1={'sharing_mobile_4.jpg'}
//                 phoneImage1Alt={"anonymisation"}
//                 phoneImage2={'sharing_mobile_5.jpg'}
//                 phoneImage2Alt="auto cropping"
//                 phoneImage2Height={660}
//                 imagesFluid={imagesFluid}
//             />
//             </div>
//             <div className={css.slide4}>
//             <SlideTypeG
//                 title="Create your feel by custom settings"
//                 fullImage={'sharing_4.jpg'}
//                 phoneImage={'sharing_mobile_7.jpg'}
//                 alt="Custom workflow"
//                 imagesFluid={imagesFluid}
//             />
//             </div>
//         </div>

//         </div>

//         <div className={css.Footer}>
//           <Footer/>
//         </div>

//       </div>
//     </Layout>
//   );
// };

// export default SharingPage;
